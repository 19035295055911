//Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { Container } from '@material-ui/core';
//Local
import { Layout } from '../../../components';
import {
  PreviewSec,
  ItemsSec,
} from '../../../components/mediaCenterSections/blogCategory';
import * as MediaCenterActions from '../../../redux/mediaCenter/actions';

const MediaCenterFans = ({ location }: { location: Object }) => {
  const tag = decodeURIComponent(location.hash.replace('#', ''));
  const fansPage = useSelector((state) => state.mediaCenter.fansPage);
  const fansArticles = useSelector((state) => state.mediaCenter.fansArticles);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getFansPageAsync());
    dispatch(MediaCenterActions.getFansArticlesAsync());
  }, [dispatch]);

  return (
    <Layout isMediaCenter>
      <Container maxWidth="md">
        <PreviewSec
          title={get(fansPage, 'pageTitle')}
          subtitle={get(fansPage, 'pageSubtitle')}
          card={{
            ...get(fansPage, 'primaryArticle.fields', {}),
            imgUrl: get(
              fansPage,
              'primaryArticle.fields.previewImage.fields.file.url'
            ),
            url: `/media_center/${get(
              fansPage,
              'primaryArticle.fields.blogSection.fields.urlKey'
            )}/article/${get(fansPage, 'primaryArticle.sys.id')}`,
            tags: get(fansPage, 'primaryArticle.fields.tags', []).map(
              (item) => item.fields.title
            ),
          }}
        />
        <ItemsSec
          title={get(fansPage, 'secondSectionTitle')}
          tags={get(fansPage, 'tags', [])}
          items={fansArticles}
          selectedTag={tag}
        />
      </Container>
    </Layout>
  );
};

export default MediaCenterFans;
